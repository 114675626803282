<template>
  <form ref="form" :class="{validated:data.validated}" class="registration" @submit.stop.prevent="methods.confirmTerms">
    <fieldset class="nickname">
      <legend>Nickname</legend>
      <input v-model="data.nickname" lang="en" maxlength="26" placeholder="Tell us who you are"
             @keypress.stop="methods.onlyEnglish"
             type="text">
      <span class="on-error">*Please input your nickname.</span>
    </fieldset>
    <fieldset class="message">
      <legend>Select a message</legend>
      <label class="custom">
        <input v-model="data.messageType" type="radio" value="custom" @change="methods.selectMessage"/>
        <span>Custom message</span>
      </label>
      <input v-model="data.customMessage" lang="en" :disabled="data.messageType !== 'custom'"
             :required="data.messageType === 'custom'"
             maxlength="26" placeholder="Write a custom message"
             @keypress.stop="methods.onlyEnglish"
             type="text">
      <span class="on-error">*Please input your custom message.</span>
      <label v-for="(message, index) in data.messages" class="provided">
        <input v-model="data.messageType" :value="index" type="radio"
               @change="methods.selectMessage"/>
        <span>{{ message }}</span>
      </label>
    </fieldset>
    <div class="buttons">
      <button @click.stop.prevent="methods.confirmBack">Go Back</button>
      <button type="submit">Done</button>
    </div>
  </form>

  <modal v-if="data.openRetakeModal" @close="data.openRetakeModal = false">
    <template v-slot:body>Retake your photo? The current profile will be deleted.</template>
    <template v-slot:buttons>
      <button @click="data.openRetakeModal = false">No</button>
      <button class="button--confirm" @click="methods.back">Yes</button>
    </template>
  </modal>

  <modal v-if="data.openTerms" class="terms-modal" @close="methods.termsClose">
    <template v-slot:body>
      <div class="head">
        <h1>#mySDCstack Privacy Policy</h1>
        <div class="select-country">
          <button
            class="select-country__current"
            @click="isSelectorOpen = !isSelectorOpen"
          >
            <span>{{ state.countryOption.find(item => item.key === state.country).value }}</span>
            <img v-if="isSelectorOpen" class="select-country__current-icon" src="@/assets/landing/icon-arrow-close.png">
            <img v-else class="select-country__current-icon" src="@/assets/landing/icon-arrow.png">
          </button>
          <ul
            v-show="isSelectorOpen"
            class="select-country__option"
          >
            <li v-for="(item, idx) in state.countryOption">
              <span
                :class="{ active: state.country === item.key }"
                @click="() => {
                  isSelectorOpen = false
                  if (state.country !== item.key) {
                    commit('changeCountry', item.key)
                  }
                }"
              >{{ item.value }}</span>
            </li>
          </ul>
        </div>
        <p v-if="state.country === 'korea'" class="effective">EFFECTIVE : 07/10/2021</p>
        <p v-else class="effective">EFFECTIVE : 06/10/2021</p>
        <template v-if="['brazil', 'turkey', 'latin', 'eu'].includes(state.country)">
          <h2>Privacy Notice</h2>
          <textarea readonly>{{ docs[state.country] }}</textarea>
        </template>
        <h2>Terms and conditions</h2>
        <p class="terms">Please agree to the following terms and conditions. You will be entered for a chance to win a prize and your stack may be featured in the gallery. You can download your personalized SDC stack and share it to your social media account without agreeing to the terms and conditions. Whether or not you agree, all data you input will securely archived internally.</p>
      </div>
      <label class="all"><input v-model="data.allCheck" readonly type="checkbox"
                                @click.stop.capture="methods.checkAll"/><span>Agree to all</span></label>
      <article v-if="['us', 'global', 'korea'].includes(state.country)">
        <label><span>Privacy Notice</span></label>
        <textarea readonly>{{ docs[state.country] }}</textarea>
      </article>
      <article>
        <label><span>User-Generated Content License Agreement</span></label>
        <textarea readonly>{{ docs.agreement }}</textarea>
      </article>
      <article>
        <label><span>User Content License</span></label>
        <textarea readonly>{{ docs.license }}</textarea>
      </article>
      <article>
        <label><span>Your Representations</span></label>
        <textarea readonly>{{ docs.representations }}</textarea>
      </article>
    </template>
    <template v-slot:buttons>
      <button @click="methods.submit(false)">No</button>
      <button :disabled="!data.allCheck" class="button--confirm" @click="methods.submit(true)">Yes</button>
    </template>
  </modal>
</template>

<script>
import {every, forEach} from 'lodash'
import {computed, onMounted, onUnmounted, ref, reactive, watch} from 'vue'
import {useStore} from '@/helper/vue'
import {useRoute} from 'vue-router'
import modal from '@/modules/modal.vue'
import brazil from '@/doc/brazil.txt'
import turkey from '@/doc/turkey.txt'
import latin from '@/doc/latin.txt'
import eu from '@/doc/eu.txt'
import us from '@/doc/us.txt'
import global from '@/doc/global.txt'
import korea from '@/doc/korea.txt'
import agreement from '@/doc/agreement.txt'
import license from '@/doc/license.txt'
import representations from '@/doc/representations.txt'

const messages = [
  "Devs don't quit. They commit",
  "Just call me the bugcatcher",
  "Real devs count from 0",
  "My first words ? \"Hello World!\""
]

const textPattern = /[^ a-zA-Z0-9?><:;,.(){}[\]~\-_+=!@#$%\^&*|'"`]/g

export default {
  components: {modal},
  emits: ['submit'],
  setup(props, {emit}) {
    const {state, commit} = useStore()
    const isSelectorOpen = ref(false)
    const {params} = useRoute()
    const data = reactive({
      messages,
      nickname: '',
      customMessage: '',
      messageType: 0,
      openRetakeModal: false,
      openTerms: false,
      validated: false,
      check: {agreement: false, license: false, representations: false},
      allCheck: computed(() => {
        return every(data.check) && data.check.agreement
      }),
      scrollTop: 0
    })
    // const form = ref('')
    const docs = {brazil, turkey, latin, eu, us, global, korea, agreement, license, representations}
    watch(() => data.nickname, (val) => {
      state.nickname = val.replace(textPattern, '')
      if (state.nickname !== data.nickname) {
        data.nickname = state.nickname
      }
    })
    watch(() => data.customMessage, (val) => {
      if (data.messageType === 'custom') {
        state.message = val.replace(textPattern, '')
        if (state.message !== data.customMessage) {
          data.customMessage = state.message
        }
      }
    })

    state.message = data.messages[data.messageType]

    const methods = {
      selectMessage: (e) => {
        if (e.target.checked && data.messageType !== 'custom') {
          state.message = data.messages[data.messageType]
        } else if (e.target.checked) {
          state.message = data.customMessage
        }
      },
      back: () => {
        history.back()
      },
      confirmBack: () => {
        data.openRetakeModal = true
      },
      confirmTerms: () => {
        data.validated = true
        data.openTerms = true
        document.body.style.overflow = 'hidden'
      },
      termsClose: () => {
        data.scrollTop = window.scrollY || document.body.scrollTop
        data.openTerms = false
        document.body.style.overflow = ''
      },
      checkAll: () => {
        const flag = data.allCheck
        forEach(data.check, (value, key) => {
          data.check[key] = !flag
        })
      },
      submit: (agreeFlag) => {
        emit('submit', agreeFlag)
      },
      onlyEnglish: (e) => {
        if (textPattern.test(e.key)) {
          e.preventDefault()
        } else {
          return true
        }
      }
    }
    onMounted(() => {
      const latin = ['es-GT', 'es-NI', 'es-DO', 'es-MX', 'es-VE', 'es-BO', 'es-AR', 'es-EC', 'qu-EC', 'es-SV', 'es-HN', 'es-UY', 'en-JM', 'es-CL', 'es-CR', 'es-CO', 'en-TT', 'es-PA', 'es-PY', 'qu-PE']
      const brazil = ['pt-BR']
      const turkey = ['tr', 'tr-TR']
      const korea = ['ko', 'ko-KR']
      const us = ['en-US']
      const eu = ['de-AT', 'fr-BE', 'nl-BE', 'bg', 'bg-BG', 'cs', 'cs-CZ', 'de-DE', 'da-DK', 'et', 'et-EE', 'es-ES', 'fi-FI', 'sv-FI', 'fr-FR', 'el', 'el-GR', 'hr', 'hr-HR', 'hu-HU', 'en-IE', 'it-IT', 'lt', 'lt-LT', 'de-LU', 'fr-LU', 'lv', 'lv-LV', 'mt-MT', 'nl-NL', 'pl-PL', 'pt-PT', 'ro', 'ro-RO', 'sv-SE', 'sl', 'sl-SI', 'sk', 'sk-SK']
      if (navigator.language) {
        if (latin.find(item => item === navigator.language)) {
          commit('changeCountry', 'latin')
        } else if (brazil.find(item => item === navigator.language)) {
          commit('changeCountry', 'brazil')
        } else if (turkey.find(item => item === navigator.language)) {
          commit('changeCountry', 'turkey')
        } else if (korea.find(item => item === navigator.language)) {
          commit('changeCountry', 'korea')
        } else if (us.find(item => item === navigator.language)) {
          commit('changeCountry', 'us')
        } else if (eu.find(item => item === navigator.language)) {
          commit('changeCountry', 'eu')
        }
      }
    })
    onUnmounted(methods.termsClose)
    return {
      state,
      commit,
      isSelectorOpen,
      data,
      methods,
      docs
    }
  }
}
</script>

<style lang="scss" scoped>
.registration {
  font-size: 16px;
  max-width: 520px;
  text-align: left;
  margin: 0;

  legend {
    font-family: 'SamsungOne', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 14px;
    @media only screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  input[type=text] {
    width: 100%;
    font-family: 'SamsungOne', sans-serif;;
    font-size: 16px;
    line-height: 22px;
    padding: 13px 24px;
    border-radius: 8px;
    border: 1px solid #000;
    appearance: none;

    & + .on-error {
      display: none;
    }

    &:disabled {
      border-color: #999;
    }

    &:focus {
      border-color: $primary-color;
    }
  }

  &.validated input {
    &:invalid {
      border-color: #ff4337;

      &::placeholder {
        color: #fff;
      }

      & + .on-error {
        position: absolute;
        display: block;
        font-family: 'SamsungOne', sans-serif;;
        font-size: 16px;
        line-height: 1.5;
        padding: 13px 24px;
        color: #ff4337;
        margin-top: -50px;
        pointer-events: none;
      }

      &:focus + .on-error {
        display: none;
      }
    }
  }

  input[type=radio] {
    width: 1px;
    height: 1px;
    vertical-align: top;
    margin: 0;
    border: 0;
    appearance: none;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #4e4e4e;
      border-radius: 3px;
    }

    &:checked::before {
      border-color: $primary-color;
      background-color: $primary-color;
      background-image: url('~@/assets/check.svg');
      background-size: 14px;
      background-position: center;
      background-repeat: no-repeat;
    }

    & + span {
      font-family: 'SamsungOne', sans-serif;;
      font-size: 16px;
      line-height: 24px;
      margin-left: 32px;
    }

    &:checked + span {
      font-weight: bold;
    }
  }
}

.nickname {

}

.message {
  margin-top: 48px;

  label {
    margin: 28px 0;
    display: block;

    &.custom {
      margin-top: 0;
      margin-bottom: 13px;
    }
  }

  .provided {
    display: block;
  }
}

.buttons {
  display: flex;
  margin: 36px -8px 0;

  @media only screen and (max-width: 600px) {
    margin: 24px 0 4px;
  }

  button {
    width: 252px;
    height: 40px;
    border: 1px solid #4e4e4e;
    border-radius: 24px;
    font-size: 14px;
    font-family: 'SamsungOne', sans-serif;;
    font-weight: bold;
    color: #4e4e4e;
    margin: 0 8px;
    @media only screen and (max-width: 600px) {
      //width: 10.6666vw
      width: 42vw;
      margin: 0;
    }

    & + button {
      @media only screen and (max-width: 600px) {
        margin-left: 2.4vw;
      }
    }

    &[type=submit] {
      background-color: $primary-color;
      border-color: $primary-color;
      color: #fff;
    }
  }
}

.terms-modal {
  ::v-deep .modal__wrapper {
    @media only screen and (max-width: 600px) {
      padding-top: 40px;
    }
  }

  ::v-deep .modal__buttons {
    text-align: center;
    @media only screen and (max-width: 600px) {
      margin-top: 47px;
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  ::v-deep .modal__container {
    width: 860px;
    max-height: calc(100vh - 120px);
    padding: 72px 78px 56px;
    text-align: left;
    overflow: auto;
    @media only screen and (max-width: 860px) {
      position: absolute;
      width: 100%;
      max-height: none;
      padding: 56px 40px 48px;
      //max-height: calc(100vh - 40px);
      top: 40px;
      bottom: 0;
    }
  }

  .head {
    .effective {
      display: inline-block;
      width: 262px;
      padding: 13px 0 11px;
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      color: #2f2f2f;
      border-radius: 24px;
      border: solid 1px #000;
      text-align: center;

      @media only screen and (max-width: 600px) {
        width: 229px;
        padding: 11px 19px 9px;
        font-size: 18px;
        line-height: 1.33;
        white-space: nowrap;
      }
    }
    .select-country {
      position: relative;
      display: inline-block;
      width: 317px;
      margin: 0 24px 0 0;
      border-radius: 24px;
      border: solid 1px #000;
      &__current {
        width: 100%;
        padding: 13px 24px 11px;
        font-family: 'SamsungOne', sans-serif;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2;
        color: #2f2f2f;
        text-align: left;
        &-icon {
          position: absolute;
          top: 50%;
          right: 24px;
          width: 24px;
          height: 24px;
          margin-top: -12px;
          z-index: 100;
        }
      }
      &__option {
        position: absolute;
        top: -1px;
        right: -1px;
        left: -1px;
        padding: 1px 0;
        margin: 0;
        background-color: #fff;
        border: solid 1px #717171;
        li {
          font-size: 20px;
          font-weight: bold;
          line-height: 2.4;
          color: #2f2f2f;
          list-style: none;
          &::before {
            content: none;
          }
          span {
            display: block;
            padding: 0 24px;
            cursor: pointer;
            &.active {
              color: #1428a0;
            }
          }
        }
      }
      @media only screen and (max-width: 600px) {
        width: 287px;
        margin: 0 0 10px;
        &__current {
          padding: 11px 19px 9px;
          font-size: 18px;
          line-height: 1.33;
          &-icon {
            right: 19px;
          }
        }
        &__option {
          li {
            font-size: 18px;
            span {
              padding: 0 19px;
            }
          }
        }
      }
    }
    h1 {
      margin-bottom: 20px;
      font-family: 'SamsungSharpSans', sans-serif;;
      font-size: 38px;
      font-weight: bold;
      line-height: 1.18;
      color: #000;
      @media only screen and (max-width: 860px) {
        font-size: 24px;
        line-height: 1.33;
      }
    }
    h2 {
      margin: 38px 0 16px;
      font-family: 'SamsungSharpSans', sans-serif;;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      color: #000;
      @media only screen and (max-width: 860px) {
        margin: 28px 0 10px;
      }

      & + textarea {
        margin-top: 0;
      }
    }

    p {
      margin: 0;
      font-family: 'SamsungOne', sans-serif;;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.5;
      color: #000;
      @media only screen and (max-width: 600px) {
        line-height: 1.38;
      }

      &.terms {
        padding-right: 74px;
        @media only screen and (max-width: 860px) {
          padding-right: 0;
        }
      }

      &.info {
        margin: -16px 0 12px;
        font-size: 13px;
        line-height: 1.38;
        color: #888;

        @media only screen and (max-width: 860px) {
          margin: -6px 0 8px;
          font-size: 11px;
          line-height: 1.36;
        }
      }
    }
  }

  label {
    display: block;
    text-align: left;
    margin-top: 8px;

    &.all {
      margin-top: 40px;
      @media only screen and (max-width: 600px) {
        margin-top: 28px;
      }
    }

    span {
      font-family: 'SamsungOne', sans-serif;;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      vertical-align: top;
    }
  }

  article {
    @media only screen and (max-width: 600px) {
      & + article {
        margin-top: 18px;
      }
    }

    label {
      @media only screen and (max-width: 600px) {
        margin: 8px 0 6px;
      }

      span {
        @media only screen and (max-width: 600px) {
          margin-left: 0;
          line-height: 1.33;
          display: block;
        }
      }
    }
  }

  textarea {
    width: 100%;
    height: 80px;
    margin: 6px auto;
    padding: 14px 16px 0;
    border-radius: 8px;
    border: solid 1px #ddd;
    background-color: #f8f8f8;
    font-family: 'SamsungOne', sans-serif;;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.38;
    color: #888;
    text-align: left;
    resize: none;
    appearance: none;
    @media only screen and (max-width: 860px) {
      width: calc(100% - 64px);
      height: 72px;
      padding: 10px 12px 0;
      font-size: 11px;
      line-height: 1.36;
      margin: 0;
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  input[type=checkbox] {
    width: 1px;
    height: 1px;
    vertical-align: top;
    margin: 0;
    appearance: none;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #4e4e4e;
      border-radius: 3px;
    }

    &:checked::before {
      border-color: $primary-color;
      background-color: $primary-color;
      background-image: url('~@/assets/check.svg');
      background-size: 14px;
      background-position: center;
      background-repeat: no-repeat;
    }

    & + span {
      margin-left: 32px;
    }
  }

  button {
    width: 252px;
    margin: 0 8px;

    &[disabled] {
      background-color: #9e9e9e;
    }

    @media only screen and (max-width: 600px) {
      width: 39.2vw;
      margin: 0 4px;
    }
  }
}
</style>
