<template>
  <div v-if="data.blob" class="layout">
    <div class="layout__block layout__block--card">
      <div class="card__wrap">
        <card-module ref="cardElement"
                     :blob="data.blob"
                     :stickers="data.stickers"
                     :nickname="data.nickname"
                     :message="data.message"
                     :url="data.url"/>
      </div>
    </div>
    <div class="layout__block layout__block--form">
      <form-module @submit="submit"/>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, reactive, ref} from 'vue'
import {useStore} from '@/helper/vue'
import {useRoute} from 'vue-router'
import router from "@/router"
import axios from 'axios'

import cardModule from '@/modules/card.vue'
import formModule from '@/modules/form.vue'

export default {
  components: {
    cardModule,
    formModule
  },
  setup() {
    const {state, commit} = useStore()
    const {params} = useRoute()
    const cardElement = ref(null)
    const data = reactive({
      nickname: computed(() => state.nickname),
      message: computed(() => state.message),
      stickers: computed(() => state.currentStickers),
      url: ref(params.url),
      blob: computed(() => state.takeImage ? URL.createObjectURL(state.takeImage.decorated) : ''),
      done: false
    })
    const submit = (agreeFlag) => {
      if (data.done) {
        return
      }
      data.done = true
      const formData = new FormData()
      formData.append('name', state.nickname)
      formData.append('message', state.message)
      formData.append('agreeYn', agreeFlag)
      formData.append('stickerInfo', JSON.stringify(scaling(state.currentStickers)))
      formData.append('images', new File([state.takeImage.origin], `${params.id}-origin.png`))
      cardElement.value.$refs.dumpCanvas.toBlob(function (blob) {
        state.takeImage.finished = blob
        formData.append('images', blob, `${params.id}.png`)
        axios.post('/user', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data && response.data.result) {
            commit('registerProfile', {
              ...params,
              ...response.data,
              message: state.message,
              nickname: state.nickname || 'SDC Developer'
            })
            router.push({name: 'share'})
          }
        })
      })

      function scaling(collection) {
        return collection.map(item => {
          item.left /= item.adjustScale
          item.top /= item.adjustScale
          item.scaleX /= item.adjustScale
          item.scaleY /= item.adjustScale
          return item
        })
      }

      // console.log(formData)

      // router.push({name: 'share', params})
    }
    onBeforeMount(async () => {
      if (!state.takeImage) await router.replace({name: 'create'})
    })
    return {
      cardElement,
      data,
      submit
    }
  }
}
</script>

<style scoped lang="scss">
.layout {
  display: flex;
  width: 100%;
  height: 0;
  min-height: 100vh;
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: block;
    height: auto;
  }

  &__block {
    flex: 1 1 auto;
    padding: 80px;
    box-sizing: border-box;
    overflow: hidden;
    @media only screen and (max-width: 600px) {
      padding: 24px;
    }

    &--card {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #ededee;
      flex: 1 1 760px;

      @media only screen and (max-width: 600px) {
        background-color: #fff;
        height: auto;
      }

      &::before {
        //content: '';
        //display: block;
        //position: absolute;
        //right: 0;
        //left: 0;
        //height: 100%;
      }

      ::v-deep .card {
        top: 0;
        left: 0;
        bottom: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 600px;
        margin-left: auto;
        transform: translate(0, -50%);
        @media only screen and (max-width: 600px) {
          top: auto;
          width: calc(100vw - 48px);
          height: calc(100vw - 48px);
          transform: none;
        }

        @media only screen and (max-width: 600px) {
          &__caption {
            height: 21.8666vw;
            padding: 2.66vw 3.2vw;

            &--nickname {
              font-size: 5.8666vw;
            }

            &--message {
              font-size: 4.8vw;
            }
          }

        }
      }

    }

    &--form {
    }
  }
}

.card__wrap {
  position: relative;
  max-width: 600px;
  width: 100%;
  top: 50%;
  height: 0;
  padding-bottom: 100%;
  margin-left: auto;
}
</style>
